// Equal column height
function height_equal() {
  $('.equal-col-wp .equal-col-item').css('minHeight', 'auto');
  $('.equal-col-wp').each(function(i) {
    var group = $(this).find('.equal-col-item');
    var tallest = 0;
    group.each(function() {
      var thisHeight = $(this).innerHeight();
      if (thisHeight > tallest) {
        tallest = thisHeight;
      }
    });
    group.css('minHeight', tallest + 5);
  });
}

// Viewport height
function height_viewport() {
  var viewport = $(window).height();
  $('#foo').height(viewport);
}

// Initialisation
height_equal();
height_viewport();

setTimeout(function() {
  height_equal();
}, 300);

$(window).on('resize', function() {
  height_equal();
});
