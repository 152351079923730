// Initialisation
function f_modalLoad(href) {
  $('#modal .modal-body').html();
  $('body').addClass('modal-loading');
  $('#modal .modal-content').load(href + ' #modal-content', function(response, status, xhr) {
    if (status == "error") {
      var msg = "Sorry but there was an error: ";
      alert(msg + xhr.status + " " + xhr.statusText);
    } else {
      $('#modal').modal();
      $('.trigger-modal-inner').on('click', function() {
        $('#modal .modal-dialog').removeClass('modal-lg modal-sm modal-dialog-centered');
        var href = $(this).attr('href');
        var size = $(this).data('size');
        var align = $(this).data('align');
        $('#modal .modal-dialog').addClass(size).addClass(align);
        f_modalLoad(href);
        return false;
      });
    }
    $('body').removeClass('modal-loading');
  });
}

$('.trigger-modal').on('click', function() {
  $('#modal .modal-dialog').removeClass('modal-lg modal-sm modal-dialog-centered');
  var href = $(this).attr('href');
  var size = $(this).data('size');
  var align = $(this).data('align');
  $('#modal .modal-dialog').addClass(size).addClass(align);
  f_modalLoad(href);
  return false;
});
