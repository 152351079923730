// JS dependecy
var magnificpopup = require('magnific-popup');

// CSS dependecy
import '/../node_modules/magnific-popup/dist/magnific-popup.css';

// Initialisation
$('.magnific-popup').each(function(i) {
  $(this).magnificPopup({
    preloader: true,
    removalDelay: 500, //delay removal
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    },
    callbacks: {
      beforeOpen: function() {
        this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
        this.st.mainClass = this.st.el.attr('data-effect');
      }
    }
  });
});
