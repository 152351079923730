// JS dependency
var parallax = require('jquery-parallax.js');

// Initialisation
$('.parallax-wp').each(function() {
  $(this).parallax({
    positionY: 'top'
  });
});

$(window).on('resize', function() {
  $(window).trigger('resize.px.parallax');
});
