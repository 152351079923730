// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 0,
  autoplaySpeed: 6000,
  appendArrows: '#slick-home-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-left"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-right"></span></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});

// Example animations
setTimeout(function() {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', function(event, slick, currentSlide) {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick-home .slick-active').removeClass('animation');
});



// Example
$('#slick-realisation').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  arrows: false,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  autoplaySpeed: 6000,
  appendArrows: '#slick-realisation-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-left"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-right"></span></button>',
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});
